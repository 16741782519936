<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Autorización</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="facturas_filtradas" :select="seleccionar" @actions="dataGridActions" @ready="dataGridReady">
			<div class="col-md-2 btn-recargar">
				<button class="btn warning-btn" @click="obtener_facturas">Recargar</button>
			</div>
			<div v-if="$auth.can('tesoreria', 'tesoreria_autorizador')" class="col-md-2 btn-revisar">
				<button class="btn complementary-btn" @click="revisar">Revisar</button>
			</div>
			<div v-if="$auth.can('tesoreria', 'cargar_facturas')" class="col-md-2 btn-cargar-factura">
				<button class="btn secondary-btn" @click="checar_subir_facturas">Cargar factura</button>
			</div>
			<div v-if="$auth.can('tesoreria', 'actualizar_factura')" class="col-md-2 btn-autorizacion-masiva">
				<button class="btn secondary-btn" @click="autorizarcion_masiva_verificar">Autorizarción masiva</button>
			</div>
			<div class="col-md-2 btn-ayuda">
				<div class="dropdown v-step-bt-4">
				  <button class="btn complementary-principal-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				    Ayuda
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'interface')">Interface</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'detalles')">Ver detalles de facturas</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'carga')">Cargar factura</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'masiva')">Autorización masiva</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'filtros')">Filtros</a>
				  </div>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="ver_detalles" :options="{width: '80vw',close:true}" @close="cancelar_revisar_factura" class="bpb-modal">
			<h2 class="title">Factura</h2>
			<div class="body">
				<FacturaView :factura="factura" @observaciones="agregar_observacion"/>
			</div>
			<div v-if="factura.estatus == 2 || step_detalles != 0" class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-3">
							<button class="btn principal-btn btn-aprobar" @click="actualizar_factura(5)">Aprobar</button>
						</div>
						<div class="col-md-3">
							<button class="btn warning-btn btn-rechazar" @click="actualizar_factura(0)">Rechazar</button>
						</div>
						<div class="col-md-3">
							<button class="btn complementary-btn btn-cancelar" @click="cancelar_revisar_factura">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="rechazar" class="bpb-modal" @close="rechazar=false">
			<h2 class="title">Rechazar factura</h2>
			<div class="body">
			    <div class="row">
			        <label for="razon_rechazo" class="col-form-label col-sm-2">Razón del rechazo</label>
			        <div class="col-sm-10">
			            <textarea v-model="observacion" name="razon_rechazo" id="razon_rechazo" cols="30" rows="10" class="form-control razon_rechazo"></textarea>
			        </div>
			    </div>
			    <br />
			    <div>
			        <div class="row">
			            <div class="col-sm-12 d-flex justify-content-end">
			                <div class="col-md-3">
			                    <button class="btn warning-btn" @click="rechazar_factura">Rechazar</button>
			                </div>
			                <div class="col-md-3">
			                    <button class="btn complementary-btn" @click="rechazar=false">Cancelar</button>
			                </div>
			            </div>
			        </div>
			    </div>
			</div>
		</Modal>

		<Modal v-if="subirFactura" class="bpb-modal">
			<h2 class="title">Cargar factura</h2>
			<div class="body">
				<div class="row form-group">
					<label for="pdf" class="col-form-label col-sm-2">Documento PDF</label>
					<div class="col-sm-10 v-step-factura-2">
						<input type="file" name="pdf" id="pdf" accept=".pdf">
					</div>
				</div>
				<div class="row form-group">
					<label for="xml" class="col-form-label col-sm-2">Documento XML</label>
					<div class="col-sm-10 v-step-factura-3">
						<input type="file" name="xml" id="xml" accept=".xml">
					</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Información de pago</label>
					<div class="col-sm-10">
						<div class="row form-group">
							<label for="xml" class="col-form-label col-sm-2">Titular de la cuenta</label>
							<div class="col-sm-10 ">
								<input v-model="informacion_pago.titular_cuenta" type="text" name="titular_cuenta" id="titular_cuenta" class="form-control">
							</div>
						</div>
						<div class="row form-group">
							<label for="xml" class="col-form-label col-sm-2">Banco</label>
							<div class="col-sm-10 ">
								<select v-model="informacion_pago.banco" name="banco" id="banco" class="form-control">
									<option v-for="valor in catalogo.valores" :key="valor.id" :value="valor.valor">{{ valor.nombre }}</option>
								</select>
							</div>
						</div>
						<div class="row form-group">
							<label for="xml" class="col-form-label col-sm-2">Nombre de contacto</label>
							<div class="col-sm-10 ">
								<input v-model="informacion_pago.nombre_contacto" type="text" name="nombre_contacto" id="nombre_contacto" class="form-control">
							</div>
						</div>
						<div class="row form-group">
							<label for="xml" class="col-form-label col-sm-2">Clabe interbancaria</label>
							<div class="col-sm-10 ">
								<input v-model="informacion_pago.clabe_interbancaria" type="text" name="clabe_interbancaria" id="clabe_interbancaria" class="form-control">
							</div>
						</div>
						<div class="row form-group">
							<label for="xml" class="col-form-label col-sm-2">Email</label>
							<div class="col-sm-10 ">
								<input v-model="informacion_pago.email_contacto" type="email" name="email_contacto" id="email_contacto" class="form-control">
							</div>
						</div>
						<div class="row form-group">
							<label for="xml" class="col-form-label col-sm-2">RFC</label>
							<div class="col-sm-10 ">
								<input v-model="informacion_pago.rfc" type="text" name="rfc" id="rfc" class="form-control">
							</div>
					</div>
				</div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Otros documentos (opcional)</label>
					<div class="col-sm-10">
						<div class="row mb-2">
							<div class="col-sm-12 text-right">
								<button class="btn btn-info v-step-factura-4" @click="cargar_documento_extra($event, true)">Cargar documento</button>
								<input type="file" name="doc_extra" id="doc_extra" style="display: none" @change="cargar_documento_extra">
							</div>
						</div>
						<div class="content-table">
							<table class="other_files">
								<thead>
									<th>#</th>
									<th>Documento</th>
									<th>&nbsp;</th>
								</thead>
								<tbody v-if="documentos_extras.length > 0">
									<tr v-for="(doc, index) in documentos_extras" :key="doc.id">
										<td>{{ (index+1) }}</td>
										<td>{{ doc.name }}</td>
										<td class="delete">
											<img @click="quitar_documento_extra(index)" style="width: 15px;" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar documento" title="Quitar documento">
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="3">No hay documentos</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row form-group">
					<label for="comentarios" class="col-form-label col-sm-2">Comentarios</label>
					<div class="col-sm-10 v-step-factura-5">
						<textarea v-model="comentarios" name="comentarios" id="comentarios" cols="30" rows="10" class="form-control"></textarea>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-3">
							<button class="btn principal-btn v-step-factura-6" @click="cargar_factura">Cargar</button>
						</div>
						<div class="col-md-3">
							<button class="btn complementary-btn" @click="cancelar_cargar_factura">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal :options="{width: '40vw', type:'error'}" v-if="alert_masivo" @close="cancelar_masiva">
			<div class="title">Autorización masiva</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12 text-center">
						<p>En tu selección hay facturas que aún no se han validado.</p><p>¿Deseas continuar?</p>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8">
						<button class="btn btn-primary" @click="autorizarcion_masiva">Continuar</button>
					</div>
					<div class="col-sm-2">
						<button class="btn btn-danger" @click="cancelar_masiva">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<v-tour name="tour-interface" :steps="steps_inteface" :options="steps_options"></v-tour>
		<v-tour name="tour-detalles" :steps="filtros_detalles_steps" :options="steps_options" :callbacks="steps_callbacks_detalles"></v-tour>
		<v-tour name="tour-filtros" :steps="steps_filtros" :options="steps_options" :callbacks="steps_callbacks_filtros"></v-tour>
		<v-tour name="tour-carga" :steps="steps_carga" :options="steps_options" :callbacks="steps_callbacks_carga"></v-tour>
		<v-tour name="tour-masiva" :steps="steps_masiva" :options="steps_options" :callbacks="steps_callbacks_masiva"></v-tour>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'
	import FacturaView from '@/apps/tesoreria/components/FacturasView'

	import tesoreria from '@/apps/tesoreria/api/tesoreria'
	import catalogos from '@/apps/tesoreria/api/catalogos'

	export default {
		components: {
			DataGrid, Modal, FacturaView
		}
		,data: () => ({
			dataGridConfig: {
				name: 'facturas'
				,cols: {
					empresa_id: 'Proveedor'
					,proveedor_id: 'Representante'
					,total: 'Total'
					,subtotal: 'Subtotal'
					,created_at: 'Fecha de subida'
					,metodo_pago: 'Método Pago'
					,revisada: 'Pre-autorización'
					,estatus: 'Estatus'
					,id: 'Documentos'
				}
				,filters: {
					cols: {
						id: 'ID'
						,uuid: 'UUID'
						,empresa_id: {
							text: 'Proveedor'
							,options: {}
						}
						,proveedor_id: {
							text: 'Representante'
							,options: {}
						}
						,subtotal: 'Subtotal'
						,total: 'Total'
						,revisada: {
							text: 'Pre-autorización'
							,options:{
								0: 'Sin validar'
								,1: 'Validada'
								,2: 'Inconsistencia'
							}
						}
						,estatus: {
							text: 'Estatus'
							,options: {
								1: 'Activo'
								,0: 'Inactivo'
							}
						}
						,created_at: 'Fecha creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					empresa_id: function(val, row) {
						return '<span style="font-size:0.9em;">'+(row.empresa ? row.empresa.empresa : 'Sin especificar')+'</span>';
					}
					,proveedor_id: function(val, row) {
						return row.proveedor_nombre;
					}
					,total: function(val,row,vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,subtotal: function(val,row,vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,uuid: function(val) {
						return '<span style="font-size:0.85em;">'+val+'</span>';
					}
					,revisada: function(val) {
						switch(val) {
							case 0:
								return '<span class="factura_validada" style="color: #929292;font-weight:bold;">Sin validar</span>';
								break
							case 1:
								return '<span class="factura_validada" style="color: #31992A;font-weight:bold;">Validada</span>';
								break;
							case 2:
								return '<span class="factura_validada" style="color: #E09431;font-weight:bold;">Inconsistencia</span>';
								break;
						}
					}
					,estatus: function(val, row, dataGrid, vuex) {
						let buscar_estatus = function(vuex, estatus) {
							let nombre = '';
							// console.log('vuex', vuex);
							if (vuex.factura_status)
								for(let i=0; i<vuex.factura_status.length; i++) {
									if (vuex.factura_status[i].llave == 'catalogo_estatus' && parseInt(vuex.factura_status[i].valor) == estatus) {
										nombre = vuex.factura_status[i].nombre;
										i=vuex.factura_status.length;
									}
								}

							return nombre;
						}

						let mssg = '';
						switch(val) {
							case 0:
								mssg = '<span class="factura_estatus" style="color:#FA3030;font-weight:bold">'+buscar_estatus(vuex, 0)+'</span>';
								break;
							case 1:
								mssg = '<span class="factura_estatus" style="color:#4545FF;font-weight:bold">'+buscar_estatus(vuex, 1)+'</span>';
								break;
							case 2:
								mssg = '<span class="factura_estatus" style="color:#FF9E44;font-weight:bold">'+buscar_estatus(vuex, 2)+'</span>';
								break;
							case 3:
								mssg = '<span class="factura_estatus" style="color:#4AD627;font-weight:bold">'+buscar_estatus(vuex, 3)+'</span>';
								break;
							case 4:
								mssg = '<span class="factura_estatus" style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 4)+'</span>';
								break;
							case 5:
								mssg = '<span class="factura_estatus" style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 5)+'</span>';
								break;
							case 6:
								mssg = '<span class="factura_estatus" style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 6)+'</span>';
								break;
						}

						return mssg
					}
					,id: function(val, row) {
						return '<button class="btn principal-btn" name="descargar_documentos" data-factura="'+encodeURI(JSON.stringify(row))+'">Descargar</button>';
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,facturas: []
			,factura: {}
			,seleccionados: []
			,seleccionar: false
			,ver_detalles: false
			,observacion: null
			,rechazar: false
			,subirFactura: false
			,comentarios: null
			,alert_masivo: false
			,empresas: []
			,documentos_extras: []
			,catalogo: []
			,informacion_pago: {
				titular_cuenta: null
				,banco: null
				,clabe_interbancaria: null
				,nombre_contacto: null
				,email_contacto: null
				,rfc: null
			}
			,steps_options: {
				labels: {
					buttonSkip: 'Finalizar'
					,buttonPrevious: 'Anterior'
					,buttonNext: 'Siguiente'
					,buttonStop: 'Finalizar'
				}
			}
			,step_inteface: 0
			,steps_inteface: [
				{
					target: '.btn-recargar'
					,content: 'Permite recargar el contenido manteniendo la configuración actual (filtros, paginado, orden, etc)'
				}
				,{
					target: '.btn-revisar'
					,content: 'Muestra información detallada de la factura seleccionada'
				}
				,{
					target: '.btn-cargar-factura'
					,content: 'Permite cargar facturas que no corresponden a ningún proveedor'
				}
				,{
					target: '.btn-autorizacion-masiva'
					,content: 'Permite autorizar multiples facturas con un solo click'
				}
				,{
					target: '.btn-ayuda'
					,content: 'Muestra información del dashboard y lista las guías de la aplicación'
				}
				,{
					target: 'table.table-striped'
					,content: 'Grid donde se muestra y ordena la información'
				}
				,{
					target: 'button[name="descargar_documentos"]'
					,content: 'Permite descargar los documentos de la factura indicada'
				}
			]
			,steps_callbacks_detalles: {}
			,step_detalles: 0
			,filtros_detalles_steps: [
				{
					target: 'label:not([for="facturas_all"])'
					,content: 'Seleccionamos una factura'
				}
				,{
					target: '.btn-revisar'
					,content: 'Muestra información detallada de la factura seleccionada'
				}
				,{
					target: 'label[for="proveedor"]'
					,content: 'Se visualiza información más detallada de la factura'
				}
				,{
					target: 'label[for="conceptos"]'
					,content: 'Listamos todos los conceptos de la factura'
				}
				,{
					target: 'label[for="impuestos"]'
					,content: 'Listamos todos los impuestos de la factura'
				}
				,{
					target: 'label[for="pagos"]'
					,content: 'Cuando la factura ya tiene pagos se listarán aquí'
				}
				,{
					target: 'label[for="observaciones"]'
					,content: 'Se listan las observaciones que se van agregando según el proceso que lleve la factura, también podrás ver observaciones que agreguen los operadores de BienParaBien'
				}
				,{
					target: '.agregar_observacion_btn'
					,content: 'Puedes agregar observaciones extras siempre y cuando la factura aún no sea pagada'
				}
				,{
					target: '.btn-aprobar'
					,content: 'Podemos aprobar la factura para que pase a su siguiente fase que puede ser "En proceso de pago", "En revisión por director de Finanzas" y "En revisión por director de General" dependiendo del monto de la factura'
				}
				,{
					target: '.btn-rechazar'
					,content: 'Si crees que la factura tiene un error o es incorrecta en cualquier sentido siempre podrás rechazarla, solo toma en cuenta que esta acción no se puede revertir'
				}
				,{
					target: '.btn-cancelar'
					,content: 'Para cerrar la ventana sin modificar la factura puedes dar click en el botón "Cancelar"'
				}
			]
			,steps_callbacks_filtros: {}
			,step_filtros: 0
			,steps_filtros: [
				{
					target: '#dataGrid-btn-add-filter'
					,content: 'Los filtros funcionan para limitar los registros a visualizar, especialmente cuando se busca un registro o registros en particular, para agregar un filtro comenzamos dando click al botón "Agregar filtro"'
				}
				,{
					target: '#filtro_columna'
					,content: 'Seleccionamos la columna a la que se le aplicará el filtro'
				}
				,{
					target: '#filtro_operador'
					,content: 'Seleccionamos el operador que se aplicará al filtro'
				}
				,{
					target: '#valor1'
					,content: 'Indicamos el valor que tendrá el filtro, esto podría ser unicamente texto o en caso de que la columna sea de un tipo en especifico deberá tener un formato en especial, por ejemplo, para fechas el formato tendrá que ser YYYY-MM-DD HH:ii:ss, puede ser solo la fecha, la fecha y hora o solo la hora, algunos operadores pueden requerir más de un dato'
				}
				,{
					target: '.dataGrid-filter-btn-add'
					,content: 'Finalmente damos click en el botón agregar o cancelar en caso que ya no desees agregar filtros'
				}
			]
			,steps_callbacks_carga: {}
			,step_carga: 0
			,steps_carga: [
				{
					target: '.btn-cargar-factura'
					,content: 'Damos click en el botón "Cargar factura"'
				}
				,{
					target: '#pdf'
					,content: 'Seleccionamos el PDF'
				}
				,{
					target: '#xml'
					,content: 'Seleccionamos el XML'
				}
				,{
					target: '.v-step-factura-4'
					,content: 'En caso de tener la necesidad de agregar mas documentos tienes la posibilidad de agregar los que necesites siempre y cuanto en conjunto no superes los 64MB de peso'
				}
				,{
					target: '.other_files'
					,content: 'Si agregas más documentos aquí se enlistarán, también podrás quitar los documentos que no quieras subir'
				}
				,{
					target: 'textarea'
					,content: 'Puedes agregar observaciones a la factura si lo necesitas'
				}
				,{
					target: '.v-step-factura-6'
					,content: 'Finalmente subimos la factura al sistema'
				}
			]
			,steps_callbacks_masiva: {}
			,step_masiva: 0
			,steps_masiva: [
				{
					target: 'label:not([for="facturas_all"])'
					,content: 'Seleccionamos al menos una factura que queramos autorizar'
				}
				,{
					target: '.factura_validada'
					,content: 'Debemos verificar que la factura aparesca como "Validada", también puedes autorizar facturas que esten como "Sin validar" solo que te pedirá confirmación'
				}
				,{
					target: '.btn-autorizacion-masiva'
					,content: 'Damos click en el botón "Autorización masiva"'
				}
				,{
					target: '.bpb_modal'
					,content: 'Si en tu lista de facturas haz seleccionado una que indique "Sin validar" se te notificará y se pedira tu autorización para continuar, si estas deacuerdo da click en "Continuar" de lo contrario en "Cancelar"'
				}
				,{
					target: '.factura_estatus'
					,content: 'Finalmente se autorizarán las facturas y estas se moverán a su siguiente fase dependiendo del monto de cada factura'
				}
			]
		})
		,created: function() {
			// this.obtener_facturas();
			this.cargar_estatus();
			this.cargar_empresas();

			this.steps_callbacks_detalles = {
				onStart: this.tour_detalles_next
				,onNextStep: this.tour_detalles_next
				,onPreviousStep: this.tour_detalles_prev
				,onStop: this.tour_detalles_stop
			}

			this.steps_callbacks_filtros = {
				onNextStep: this.tour_filtros
				,onPreviousStep: this.tour_filtros_prev
				,onStop: this.tour_filtros_stop
			}

			this.steps_callbacks_carga = {
				onNextStep: this.tour_carga
				,onPreviousStep: this.tour_carga_prev
				,onStop: this.tour_carga_stop
			}

			this.steps_callbacks_masiva = {
				onStart: this.tour_masiva
				,onNextStep: this.tour_masiva
				,onPreviousStep: this.tour_masiva_prev
				,onStop: this.tour_masiva_stop
			}
		}
		,methods: {
			obtener_facturas: function() {
				tesoreria.obtener_facturas_autorizador(this.options)
				.then(res => {
					this.$log.info('res', res);
					this.facturas = res.data.data;

					for(let i=0; i<res.data.data.length; i++) {
						this.dataGridConfig.filters.cols.proveedor_id.options[res.data.data[i].proveedor_id] = res.data.data[i].proveedor_nombre;
					}

					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err, 'Error');
				});
			}
			,cargar_empresas: async function() {
				try {
					let empresas = (await tesoreria.listar_empresas()).data;

					for(let i=0; i<empresas.length; i++) {
						this.dataGridConfig.filters.cols.empresa_id.options[empresas[i].id] = empresas[i].empresa;
					}
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,dataGridActions: function(type, data) {
				if (type == 'options') {
					this.options = data;
					this.obtener_facturas();
				}else {
					this.seleccionados = data;
					this.seleccionar = true;
				}
			}
			,dataGridReady: function() {
				// this.$log.info('Ready!');
				document.querySelectorAll('button[name=descargar_documentos]').forEach(btn => {
					// this.$log.info('btn', btn);
					btn.removeEventListener('click', this.descargar_documentos);
					btn.addEventListener('click', this.descargar_documentos);
				})
			}
			,descargar_documentos: async function(e) {
				let factura = JSON.parse(decodeURI(e.target.getAttribute('data-factura')));
				try {
					let res = (await tesoreria.descargar_documentos_v2(factura.id)).data;
					let domain = process.env.VUE_APP_API_TESORERIA;
          domain = domain.replace('api/v1','storage');
          window.open(domain+'/'+res.file);
				}catch (e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,revisar: function() {
				if (this.seleccionados.length > 0) {
					this.factura = this.seleccionados[0];
					this.ver_detalles = true;
				}else {
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error'
						,text: 'Debes seleccionar una factura'
						,duration: 5000
					});
				}
			}
			,agregar_observacion: function(observacion) {
				if (observacion && observacion.length > 5) {
					tesoreria.enviar_observaciones_factura(this.factura.id, {observacion: observacion})
					.then(res => {
						this.$log.info('res', res);
						this.factura.observaciones = res.data;
						this.observacion = null;
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})

					return true;
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
					return false;
				}
			}
			,rechazar_factura: async function() {
				if (this.observacion && this.observacion.length > 5) {
					let res = await this.agregar_observacion(this.observacion);

					if (res) {
						this.factura.estatus = 0;
						await tesoreria.actualizar_factura(this.factura.id, this.factura)
						.then(res => {
							this.$log.info('res', res);

							this.rechazar = false;
							this.observacion = null;
							this.cancelar_revisar_factura();
							this.obtener_facturas();
						})
						.catch(err => {
							this.$log.info('err', err);
							this.$helper.showAxiosError(err, 'Error al rechazar factura');
						})
					}
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
				}
			}
			,actualizar_factura: function(estatus) {
				if (this.step_detalles == 0) {
					if (estatus == 5) {
						this.factura.estatus = estatus;

						tesoreria.actualizar_factura(this.factura.id, this.factura)
						.then(res => {
							this.$log.info('res', res);
							this.ver_detalles = false;
							this.factura = null;
							this.seleccionar = false;
							this.obtener_facturas();
						})
						.catch(err => {
							this.$log.error('err', err);

							this.$helper.showAxiosError(err, 'Error al actualizar factura');
						})
					}else {
						this.rechazar = true;
					}
				}
			}
			,cancelar_revisar_factura: function() {
				if (this.step_detalles == 0) {
					this.ver_detalles = false;
					this.factura = {}
					this.seleccionar = false;
				}
			}
			,cargar_factura: function(e) {
				e.target.disabled = true;
				let form = new FormData();
				form.append('pdf', document.getElementById('pdf').files[0]);
				form.append('xml', document.getElementById('xml').files[0]);
				
				this.$log.info('informacion_pago',this.informacion_pago);
				form.append('titular_cuenta', this.informacion_pago.titular_cuenta);
				form.append('banco', this.informacion_pago.banco);
				form.append('clabe_interbancaria', this.informacion_pago.clabe_interbancaria);
				form.append('nombre_contacto', this.informacion_pago.nombre_contacto);
				form.append('email_contacto', this.informacion_pago.email_contacto);
				form.append('rfc', this.informacion_pago.rfc);
				
				form.append('comentarios', this.comentarios);

				tesoreria.cargar_factura(this.$auth.getUser().id, form)
				.then(res => {
					this.$log.info('res', res);
					e.target.disabled = false;

					if (res.data.success) {
						this.subirFactura = false;
						this.obtener_facturas();

						this.$notify({
							group: 'notifications'
							,type: 'success'
							,title: 'Factura cargada'
							,text: 'Factura cargada al sistema a la espera de validación'
							,duration: 5000
						});
					}else {
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error al cargar factura'
							,text: err.response.data.message
							,duration: 5000
						});
					}
				})
				.catch(err => {
					this.$log.info('err', err);
					e.target.disabled = false;

					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error al cargar factura'
						,text: err.response.data.message
						,duration: 5000
					})
				})
			}
			,cargar_estatus: function() {
				if (!this.$store.state.factura_status)
					tesoreria.cargar_estatus()
					.then(res => {
						this.$log.info('res', res);
						this.$store.commit('set', {factura_status: res.data});
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
			}
			,checar_subir_facturas: async function() {
				if (this.$auth.can('tesoreria','cargar_facturas')) {
					this.catalogo = (await catalogos.buscar_catalogo('bancos')).data;

					this.subirFactura = true;
				}else
					this.$helper.showMessage('Error','No tienes permisos para subir facturas','error','alert');
			}
			,autorizarcion_masiva_verificar: function() {
				if (this.seleccionados.length > 0) {
					let noValidada = [];
					let fueraRango = false;
					for(let i=0; i<this.seleccionados.length; i++) {
						if (this.seleccionados[i].estatus == 2) {
							if (this.seleccionados[i].revisada != 1) {
								noValidada.push(this.seleccionados[i].uuid);
							}
						}else {
							this.$helper.showMessage('Error','No puedes autorizar facturas que no tengan estatus "Aceptada sin revisión"','error','alert');
							i=this.seleccionados.length;
							return;
						}
					}

					if (noValidada.length == 0) {
						this.autorizarcion_masiva();
					}else {
						this.alert_masivo = true;
					}

				}else {
					this.$helper.showMessage('Error','Debes seleccionar al menos un a factura a autorizar', 'error', 'alert');
				}
			}
			,autorizarcion_masiva: async function() {
				if (this.step_masiva == 0) {
					let facturas_fallidas = [];
					for(let i=0; i<this.seleccionados.length; i++) {
						let factura = this.seleccionados[i];
						try {
							factura.estatus = 5;
							await tesoreria.actualizar_factura(factura.id, factura)
						}catch(e) {
							this.$log.info('Error en factura '+factura.uuid, e);
							facturas_fallidas.push(factura.uuid);
						}
					}

					this.cancelar_masiva();
					this.obtener_facturas();
				}
			}
			,cancelar_masiva: function() {
				if (this.step_masiva == 0) {
					this.seleccionar = false;
					this.alert_masivo = false;
				}
			}
			,cargar_documento_extra: function(e, button) {
				this.$log.info('button',button);

				if (button)
					document.getElementById('doc_extra').click();
				else {
					if (document.getElementById('doc_extra').files.length > 0) {
						let file = document.getElementById('doc_extra').files[0];

						let encontrado = false;
						for(let i=0; i<this.documentos_extras.length; i++) {
							if (this.documentos_extras[i].name == file.name) {
								encontrado = true;
								i = this.documentos_extras.length;
							}
						}

						if (!encontrado)
							this.documentos_extras.push(file);
						else
							this.$helper.showMessage('Error','Ya se cargo un archivo con el nombre "'+file.name+'"','error','alert');
					}
				}
			}
			,quitar_documento_extra: function(index) {
				let docs = []

				for(let i=0; i<this.documentos_extras.length; i++) {
					if (i != index)
						docs.push(this.documentos_extras[i]);
				}

				this.documentos_extras = docs;
			}
			,cancelar_cargar_factura: function() {
				this.subirFactura = false;
				this.documentos_extras = [];
			}
			,cargar_factura: async function() {
				let pdf = document.getElementById('pdf');
				let xml = document.getElementById('xml');

				if (pdf.files.length == 0 || xml.files.length == 0)
					return this.$helper.showMessage('Error','Debes seleccionar el PDF y el XML a cargar','error','alert');

				let data = new FormData();

				data.append('pdf', pdf.files[0]);
				data.append('xml', xml.files[0]);

				this.$log.info('informacion_pago',this.informacion_pago);
				data.append('titular_cuenta', this.informacion_pago.titular_cuenta);
				data.append('banco', this.informacion_pago.banco);
				data.append('clabe_interbancaria', this.informacion_pago.clabe_interbancaria);
				data.append('nombre_contacto', this.informacion_pago.nombre_contacto);
				data.append('email_contacto', this.informacion_pago.email_contacto);
				data.append('rfc', this.informacion_pago.rfc);

				if (document.getElementById('comentarios').value != '')
					data.append('comentarios',document.getElementById('comentarios').value);

				if (this.documentos_extras.length > 0)
					for(let i=0; i<this.documentos_extras.length; i++) {
						data.append('extra[]',this.documentos_extras[i]);
					}

				try {
					let res = (await tesoreria.cargar_factura(this.$auth.getUser().id, data)).data;

					if (res.success) {
						this.$helper.showMessage('Factura subida', 'La factura se subio de forma correcta');
						this.cancelar_cargar_factura();
						this.obtener_facturas();
					}
					else
						this.$helper.showMessage('Error',res.message, 'error', 'alert');
				}catch(e) {
					this.$helper.showAxiosError(e, 'Error');
				}
			}

			,iniciar_tour: function(e, tipo) {
				e.preventDefault();

				switch(tipo) {
					case 'interface':
						this.$tours['tour-interface'].start();
						break;
					case 'detalles':
						if (this.facturas.length == 0) {
							this.$helper.showMessage('Error','Para iniciar esta guía es necesario tener al menos una factura cargada','error','alert');
							break;
						}
						this.$tours['tour-detalles'].start();
						break;
					case 'filtros':
						this.$tours['tour-filtros'].start();
						break;
					case 'carga':
						this.$tours['tour-carga'].start();
						break;
					case 'masiva':
						if (this.facturas.length == 0) {
							this.$helper.showMessage('Error','Para iniciar esta guía es necesario tener al menos una factura cargada','error','alert');
							break;
						}
						this.$tours['tour-masiva'].start();
						break;
				}
			}
			,tour_filtros: function(paso) {
				this.$log.info('step filtros', paso);
				this.step_filtros = paso;

				if (paso == 0)
					document.getElementById('dataGrid-btn-add-filter').click();

				if (paso >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(paso) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_prev: function() {
				this.step_filtros -= 1;
				this.$log.info('step filtros prev', this.step_filtros);

				if (this.step_filtros >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(this.step_filtros) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_stop: function() {
				document.querySelector('button.dataGrid-filter-btn-cancel').click();
				this.step_filtros = 0;
			}
			,tour_detalles_next: function(step) {
				this.$log.info('step',step)
				this.step_detalles = step;

				if (typeof(step) == 'undefined')
					document.querySelector('input[type="checkbox"][name="facturas"]').click();

				if (step == 1)
					this.revisar();
			}
			,tour_detalles_prev: function(step) {
				this.step_detalles -= 1;
				this.$log.info('step',this.step_detalles)

				if (this.step_detalles == 0) {
					this.cancelar_revisar_factura();
					setTimeout(() => {
						document.querySelector('input[type="checkbox"][name="facturas"]').click();
					},20);
				}
			}
			,tour_detalles_stop: function() {
				this.step_detalles = 0;
				this.cancelar_revisar_factura();
			}
			,tour_carga: function(step) {
				this.$log.info('carga', step);
				this.step_carga = step;

				if (step == 0)
					this.checar_subir_facturas();
			}
			,tour_carga_prev: function(step) {
				this.step_carga -= 1;
				this.$log.info('step_carga', this.step_carga);

				if (this.step_carga < 0)
					this.cancelar_cargar_factura();
			}
			,tour_carga_stop: function(step) {
				this.cancelar_cargar_factura();
				this.step_carga = 0;
			}
			,tour_masiva: function(step) {
				this.$log.info('masiva', step);
				this.step_masiva = step;

				if (typeof(step) == 'undefined')
					document.querySelector('input[type="checkbox"][name="facturas"]').click();

				if (step == 2)
					this.alert_masivo = true;

				if (step == 3)
					this.alert_masivo = false;
			}
			,tour_masiva_prev: function(step) {
				this.step_masiva -= 1;
				this.$log.info('masiva', this.step_masiva);

				if (this.step_masiva == 1)
					this.alert_masivo = false;

				if (this.step_masiva == 2)
					this.alert_masivo = true;

				if (this.step_masiva == 3)
					this.alert_masivo = false
			}
			,tour_masiva_stop: function() {
				this.step_masiva = 0;
				this.alert_masivo = false;
				this.seleccionar = false;
			}
		}
		,computed: {
			total_procesada: function() {
				return '$'+this.factura.total
			}
			,subtotal_procesada: function() {
				return '$'+this.factura.subtotal
			}
			,facturas_filtradas: function() {
				let idAutorizadores = [];
				let facturas = [];

				for(let i=0; i<this.facturas.length; i++) {
          let tiene_autorizadores = (this.facturas[i].proveedor && this.facturas[i].proveedor.autorizadores && this.facturas[i].proveedor.autorizadores[0]);

					if (tiene_autorizadores && idAutorizadores.indexOf(this.facturas[i].proveedor.autorizadores[0].id) == -1)
						idAutorizadores.push(this.facturas[i].proveedor.autorizadores[0].id);
				}

				for(let i=0; i<this.facturas.length; i++) {
					if (!this.facturas[i].autorizador_id || idAutorizadores.indexOf(this.facturas[i].autorizador_id) != -1)
						facturas.push(this.facturas[i]);
				}

				return this.facturas;
			}
		}
	}
</script>

<style lang="scss">
	.other_files {
		width: 100%;

		thead {
			background-color: #000000 !important;
			color: #fff !important;

			th {
				padding: 3px 7px;
			}

			th:first-child {
				width: 25px;
				text-align: center;
			}

			th:last-child {
				width: 60px;
			}
		}

		tbody {
			tr {
				td:first-child {
					width: 20px;
					text-align: center;
				}

				td.delete {
					widows: 20px;
					text-align: center;

					img {
						width: 15px;
					}
				}
			}

			tr:nth-child(2n+2) {
				background-color: #D9D9D9 !important;
			}
		}
	}

	.observaciones {
		max-height: 200px;
		overflow: auto;

		.row {
			margin-right: 3px;
			padding: 3px;

			span.nombre {
				font-weight: bold;
			}

			span.fecha {
				font-size: 0.8em;
			}
		}

		.row:nth-child(2n+0) {
			background-color: #E6E6E6;
		}
	}

	.razon_rechazo {
		width: 100%;
		height: 170px !important;
		resize: none;
	}
</style>